.hive-btn,
.hive-btn:disabled {
  background-color: #ffb300;
  border-color: #ffb300 !important;
  font-size: 20px;
  height: 55px;
  color: #fff;
  border-radius: 0;
}

.hive-btn:hover,
.hive-btn:focus,
.hive-btn:active {
  background-color: #ffd000 !important;
  border-color: #ffd000 !important;
  color: white !important;
}

.hive-btn-2 {
  background-color: #11be6a;
  border-color: #11be6a !important;
  font-size: 20px;
  height: 55px;
  color: #fff;
  border-radius: 0;
}

.hive-btn-2:hover {
  background-color: #0ea55a !important;
  border-color: #0ea55a !important;
}

.text-underline-forgot {
  text-decoration: underline;
}

.text-underline-forgot:hover {
  color: #ffb300;
  text-decoration: underline;
}

.email-icon {
  position: absolute;
  right: 21px;
  transform: translate(50%, 50%);
}

.drop-hexagon {
  position: relative;
  width: 27px;
  height: 31px;
  background-color: transparent;
  clip-path: polygon(48% 4%, 76% 24%, 85% 70%, 60% 100%, 18% 75%, 21% 14%);
  overflow: hidden;
}

.drop-hexagon img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 767px) {
  .log-img {
    display: none !important;
  }
}
