  .capt-tabs .ant-tabs-tab {
    padding: 0;
    color: white;
}

.capt-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-tab-btn:focus {
    padding: 0;
    color: #393939;
}

.capt-tabs .ant-tabs-ink-bar {
    display: none;
  }

  .capt-tabs .ant-tabs-top > .ant-tabs-nav::before{
    display: none;
  }

  .MuiButtonBase-root.MuiButton-root.Mui-disabled{
    background-color: var(--disabled-btn);
    color: var(--body_color);
  }

  @media screen and (min-width: 350px) and (max-width: 1000px) {
  
    .capt-tabs .ant-tabs-tab {
      font-size: 14px;
    }
  
  }